import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useUserStore = defineStore('user', () => {
    const token = ref('');
    const inviteCode = ref('');
    const thirdParty = ref('petians');
    const thirdPartyToken = ref('');
    const imcToken = ref('');
    const appId = ref('');

    function loginByThirdParty(val: string, type: string = 'ymall') {
        clear();
        if (type === 'ymall') {
            token.value = val;
        } else {
            thirdPartyToken.value = val;
            // TODO get token
            token.value = val;
        }
        thirdParty.value = type;
    }

    function login(val: string) {
        if (token.value !== val) {
            token.value = val;
            thirdPartyToken.value = '';
            imcToken.value = '';
        }
    }

    function clear() {
        token.value = '';
        thirdPartyToken.value = '';
        imcToken.value = '';
    }

    const hasLogin = computed(() => !!token.value);

    return { token, login, clear, hasLogin, inviteCode, thirdPartyToken, thirdParty, imcToken, loginByThirdParty, appId };
}, {
    persist: true,
});
