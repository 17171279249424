<script setup lang="ts">
import 'virtual:css-vars.css';
import { onHide, onLaunch, onShow } from '@dcloudio/uni-app';
import { useUserStore } from './store/user';

const userStore = useUserStore();

onLaunch(({ query }) => {
    console.log('App Launch', query);
    if (query && query.token) {
        userStore.login(query.token);
        if (query.appId) {
            userStore.appId = query.appId;
        }
    }
});

onShow(() => {
    console.log('App Show');
});

onHide(() => {
    console.log('App Hide');
});
</script>

<style lang="scss">
@import 'app.scss';
@import '@/assets/styles/common.scss';
</style>
